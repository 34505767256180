<template>
  <div id="app">
    <!-- 输入框 -->
    <p class="all"><span class="half">汉唐配件搜索</span>汉唐配件搜索</p>
    <input type="text" v-model="keyword" placeholder="输入进行搜索" />

    <!-- 搜索结果列表 -->
    <table>
      <thead>
        <tr>
          <th>名称</th>
          <th>单价</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredData" :key="item.name">
          <td>{{ item.name }}</td>
          <td>{{ item.price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import excelfile from "@/assets/excel/data.json";
export default {
  data() {
    return {
      keyword: "", // 搜索关键词
      data: excelfile,
    };
  },
  methods: {
    // readExcel() {
    //   console.log(excelfile);
    // },
  },
  computed: {
    // 根据关键词过滤数据
    filteredData() {
      return this.data.filter((item) => {
        return item.name.toLowerCase().includes(this.keyword.toLowerCase());
      });
    },
  },
  mounted() {
    // this.readExcel();
  },
};
</script>

<style>
/* 移动优先的样式 */
body {
  font-size: 14px;
}

/* 在大屏幕上增强样式 */
@media (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
p {
  font-family: "微软雅黑"; /*设置字体*/
  font-size: 40px; /*设置文字大小*/
  color: rgb(26, 216, 206); /*设置文字颜色*/
  text-align: center; /*设置文字居中*/
  font-weight: border;
}

.half {
  font-weight: border;
  height: 30px; /*设置元素高度*/
  position: absolute; /*绝对定位*/
  overflow: hidden; /*隐藏溢出部分内容*/
  color: rgb(92, 255, 86); /*设置文字颜色*/
}

input {
  width: 250px;
  height: 30px;
  border-radius: 10px;
  border: 1px dotted #000000;
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  color: #999;
  text-align: center;
}
table {
  width: 100%;
  border-collapse: collapse;
}

table caption {
  font-size: 2em;
  font-weight: bold;
  margin: 1em 0;
}

th,
td {
  border: 1px solid #999;
  text-align: center;
  padding: 20px 0;
}

table thead tr {
  background-color: #2fafaf;
  color: #fff;
}

table tbody tr:nth-child(odd) {
  background-color: #eee;
}

table tbody tr:hover {
  background-color: #ccc;
}

table tbody tr td:first-child {
  color: #f40;
}

table tfoot tr td {
  text-align: right;
  padding-right: 20px;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: hsla(0, 0%, 53%, 0.4);
}

*::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 53%, 0.4);
  border-radius: 4px;
}
</style>
